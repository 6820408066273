import CONSTANTS from '../constants'
import { postRequest } from '../../services'
import { getConfigDetails } from '../../services/config'


// sing_in
export const submitAnswer = (payload) => (dispatch) => {
  dispatch({ type: CONSTANTS.SUBMIT_ANSWER_REQUEST })
  return postRequest(getConfigDetails().baseURL, payload).then((res) => {
    dispatch({ type: CONSTANTS.SUBMIT_ANSWER_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.SUBMIT_ANSWER_FAILURE })
    return err
  })
}

// sing_up

export const userSingUp = (creds) => (dispatch) => {
  dispatch({ type: CONSTANTS.SINGUP_REQUEST })
  return postRequest(getConfigDetails().baseURL, creds).then((res) => {
    dispatch({ type: CONSTANTS.SINGUP_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.SINGUP_FAILURE })
    return err
  })
}

export const sendEmailResetPassword = (payload) => (dispatch) => {
  dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_REQUEST })
  return postRequest(getConfigDetails().baseURL, payload).then((res) => {
    dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_FAILURE })
    return err
  })
}


// RESET PASSWORD
export const UpdateUserpassword = (payload) => (dispatch) => {
  dispatch({ type: CONSTANTS.RESET_PASSWORD_REQUEST })
  return postRequest(getConfigDetails().baseURL, payload).then((res) => {
    dispatch({ type: CONSTANTS.RESET_PASSWORD_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.RESET_PASSWORD_FAILURE })
    return err
  })
}


export const handleUserLogOut = () => {
  localStorage.clear()
}