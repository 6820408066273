import React from 'react'
import { strings } from '../../constants/globalConstants'
import Dismigras from '../../assets/img/Dismigras-logo-sm1.png'
import LDnil from '../../assets/img/LDnil-Logo-sm1.png'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
function SideSection(props) {  
    const {isSignUp} = props     
	return (
        <div className="col-md-6 tab-100 order_1">
            <div className={isSignUp ? 'signUpDiv side-text': 'side-text'}>
                <article>
                    <span>{strings?.surveyHeading}</span>
                    <h1 className="main-heading">{strings?.statinUsage}</h1>
                    <p>{strings?.welcomeMessage}</p>
                </article>
                <div className="poweredby">
                <p>{strings?.poweredBy}</p>
                <ul>
                <li className="firstli"><img src={LDnil} alt="LDnil"/></li>
                <li className="secondli ms-1"> <img src={Dismigras} alt="Dismigras"/></li>
                </ul>
                </div>
            </div>
        </div>
	)
}

SideSection.propTypes = {
    isSignUp: PropTypes.bool,
}
  
SideSection.defaulProps = {
    isSignUp: false,
}

function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
        loader: singInAndSingUp?.loader,
        isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(SideSection)