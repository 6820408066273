const VALIDATION_VALUES = {
  ZERO_VALUE: 0,
  DECIMAL_ONE: 0.1,
  MIN_VALUE: 1,
  MAX_VALUE_5: 5,
  MAX_VALUE_10: 10,
  MAX_VALUE_15: 15,
  MAX_VALUE_40: 40,
  MAX_VALUE_16: 16,
  MAX_VALUE_64: 64,
  MAX_VALUE_128: 128,
  MAX_VALUE_10240: 10240,
  MAX_VALUE_1024: 1024,
  MAX_VALUE_2048: 2048,
  MAX_VALUE_1048576: 1048576,
  MAX_PCT_VALUE_100: 100,
  MAX_SALARY_VALUE: 1000000000,
  MAX_POSITIVE_INTEGER_VALUE: 2147483647,
}
export default VALIDATION_VALUES