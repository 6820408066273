import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { ERROR_MESSAGE } from '../../constants/errorMessage'
import { EMPTY_ARRAY, EMPTY_OBJECT, ratingArray } from '../../constants/globalConstants'
import REGEX_PATTERN from '../../constants/regex'
import URL from '../../constants/urls'
import { userSingIn } from '../../redux/actions/singinAndSingUp'
import { Circles } from 'react-loader-spinner'
import { useState } from 'react'
import { convertJSONtoFormData, encryptMailId, hasOtherValue } from '../../utils/helper'
import { exportReport } from '../../redux/actions/export&Download'
import { InputGroup } from 'react-bootstrap'
import { submitAnswer } from '../../redux/actions/submitanswer'
import { useEffect } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { fetchQuestions } from '../../redux/actions/questions'
import Loader from '../../global/Loader'

function Exam(props) {
    const {dispatch, notify, isLoggedIn} = props
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue, resetField, unregister } = useForm()
    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [displayField, setDisplayField] = useState(EMPTY_ARRAY)
    const [multiAnswers, setMultiAnswers] = useState(EMPTY_ARRAY)
    const [userData, setUserData] = useState(EMPTY_OBJECT)
    const [questionSet, setQuestionSet] = useState(EMPTY_ARRAY)
    
    useEffect(() => {
        
        const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : EMPTY_OBJECT
        // if(userData?.examAttended == 1){
        //     navigate(URL?.THANKYOU)
        // }
        if(userData?.userid && userData?.userid != '' && isLoggedIn){
            setLoader(true)
            const payload = {apicall: 'questions', username: userData?.userid}
            const formData = convertJSONtoFormData(payload)
            dispatch(fetchQuestions(formData)).then((res) => {
                setLoader(false)
                if(res && !res.error){
                    setQuestionSet(res?.questions)
                }else{
                    setQuestionSet(EMPTY_ARRAY)
                    notify('error', res?.message ? res?.message : 'Something went Wrong!')
                }
            }).catch((err) =>{
                setLoader(false)
                notify('error', err?.message ? err?.message : 'Something went Wrong!')
            })
            setUserData(userData)
        }else{
            navigate(URL?.LOGIN)
        }
    }, [EMPTY_ARRAY, isLoggedIn])

    const navigateToSignUp = () => {
        navigate(URL?.SIGNUP)
    }
    const navigateToResetPassword = () => {
        navigate(URL?.FORGOTPASSWORD)
    }

    const submitExam = (data) => {
        data.q16 = multiAnswers
        delete data.q161
        delete data.q162
        delete data.q163
        delete data.q164
        delete data.q165
        delete data.q166
        delete data.q167
        delete data.q168
        
        setLoader(true)
        const payload = {answers: JSON.stringify(data), apicall: 'submitexam', user_id: userData?.userid}
        const formData = convertJSONtoFormData(payload)
        if(!loader){
            dispatch(submitAnswer(formData)).then((res) => {
                setLoader(false)
                if(res && !res.error){
                    
                    navigate(URL?.THANKYOU)
                    // notify('success', res?.message ? res?.message : 'LoggedIn Successfully!')
                }else{
                    notify('error', res?.message ? res?.message : 'Something went Wrong!')
                }
            }).catch((err) =>{
                setLoader(false)
                notify('error', err?.message ? err?.message : 'Something went Wrong!')
            })
        }
    }

    
    const togglePasswordVisiblity = () => {
        setShowPassword(!showPassword)
    }
    
    const handleOtherOption = (e) => {
        setValue(e?.target?.name, e?.target?.value)
        console.log(e?.target?.name, e?.target?.value)
        const hideField = {
            key: e?.target?.name, 
            value: e?.target?.value
        }

        const filterData = displayField.filter((item) => item?.key == e?.target?.name && item?.value != e?.target?.value && item?.value == 'Others')

        if(filterData?.length > 0){
            unregister(e?.target?.name)
            resetField(e?.target?.name)
        }

        const updatedData = displayField.map(item => {
            if (item.key === hideField.key && item.value !== hideField.value) {
                return hideField
            }
            return item
        })
        console.log(displayField, updatedData)
        
        if (!updatedData.some(item => item.key === hideField.key)) {
            updatedData.push(hideField)
        }
        // const updatedData = [...displayField, hideField]
        setDisplayField(updatedData)
        console.log(updatedData)
    }

    const handleMultiCheck = (e, que) => {
        const hideField = {
            key: que, 
            value: e?.target?.value
        }
        const updatedData = multiAnswers.map(item => {
            if (item.key === hideField.key && item.value !== hideField.value) {
                return hideField
            }
            return item
        })
        
        if (!updatedData.some(item => item.key === hideField.key)) {
            updatedData.push(hideField)
        }
        setMultiAnswers(updatedData)
    }

    const handleModal = () => {
        setModalOpen(!modalOpen)
    }

	return (
            <main className="overflow-hidden internalpage">
                <div className="wrapper">
                    <div className="main-inner">
                        <div className="form w-100 ms-auto h-100 align-content-center maincontentwrap">
                        {loader ? <Loader/> :<div className='d-flex flex-column align-items-center m-0 p-0'>
                                <div className='w-100 d-flex justify-content-between'>
                                    <h3 className='fw-bold'>Questionnaire</h3>
                                    <span className='text-decoration-underline pointer insbtn' onClick={() => handleModal()}>Instructions <i className='fa fa-question-circle'></i></span>
                                </div>
                                <div className='col-12 col-sm-12 border rounded p-1'>
                                    <form autoComplete='on' className='d-flex row' onSubmit={handleSubmit(submitExam)}>
                                        {(questionSet && questionSet?.length > 0) ? questionSet?.map((question, index) => {
                                            if(question?.type == 'radio'){
                                                return(
                                                    <div key={index} className='col-sm-12 col-12'>    
                                                        <label className='question mt-2 mb-2' htmlFor={`q${question?.id}`}>{`Q${index+1}. ${question?.question}`}</label>

                                                        {(question?.options && question?.options?.length > 0) ? question?.options?.map((option, optionIndex) => {
                                                            if(option != 'Others'){
                                                                return (<div key={optionIndex} className="form-check ">
                                                                    <input className="form-check-input" type="radio" value={option} id={`q${question?.id}${optionIndex+1}`}
                                                                        {...register(`q${question?.id}`, {
                                                                        required: true
                                                                    })}
                                                                    onChange={(e) => handleOtherOption(e)}
                                                                    />
                                                                    <label className="form-check-label options" htmlFor={`q${question?.id}${optionIndex+1}`}>
                                                                        {option}
                                                                    </label>
                                                                </div>)
                                                            }else{
                                                                return (
                                                                    <div key={optionIndex}>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio" value={option} id={`q${question?.id}${optionIndex+1}`}
                                                                            {...register(`q${question?.id}`, {
                                                                                required: true
                                                                            })}
                                                                                onChange={(e) => handleOtherOption(e)}  
                                                                            />
                                                                            <label className="form-check-label options" htmlFor={`q${question?.id}${optionIndex+1}`}>
                                                                                {option}
                                                                            </label>
                                                                        </div>
                                                                        {hasOtherValue(displayField, `q${question?.id}`) ? <div className='col-sm-6 col-12'>    
                                                                            <label className='options mt-2 mb-2' htmlFor={`q${question?.id}${optionIndex+1}`}>Please Specify </label>
                                                                            <input 
                                                                                {...register(`q${question?.id}`, {
                                                                                    required: { value: true, message: ERROR_MESSAGE?.REQ_QUESTION_MSG },
                                                                                })}
                                                                                className='form-control mt-2 mb-2'
                                                                                type='text'
                                                                                id={`q${question?.id}${optionIndex+1}`}
                                                                                placeholder='Please specify'
                                                                            />
                                                                        </div> : null}
                                                                    </div>
                                                                )
                                                            }
                                                        }) : null}
                                                        
                                                        
                                                        {(errors?.[`q${question?.id}`]) ? <span className='text-danger'>{ERROR_MESSAGE?.REQ_QUESTION_MSG}</span> : null}
                                                    </div>
                                                )
                                            }else if(question?.type == 'multiradio'){
                                                return(<div key={index} className='col-sm-12 col-12'>    
                                                    <label className='question mt-2 mb-2' htmlFor={`q${question?.id}`}>{`Q${index+1}. ${question?.question}`}</label>
                                                    <table className='table'>
                                                        <tr className='options'>
                                                            <td></td>
                                                            <td>Atorvastatin</td>
                                                            <td>Rosuvastatin</td>
                                                        </tr>
                                                        
                                                        {(question?.options && question?.options?.length > 0) ? question?.options?.map((option, optionIndex) => {
                                                            return(
                                                                <tr key={optionIndex}>
                                                                    <td className='options'>{option}</td>
                                                                    <td>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio" value="Atorvastatin" id={`q${question?.id}${optionIndex+1}`}
                                                                            {...register(`q${question?.id}${optionIndex+1}`, {
                                                                                required: true
                                                                            })}
                                                                            onChange={(e) => handleMultiCheck(e, option)}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio" value="Rosuvastatin" id={`q${question?.id}${optionIndex+1}`}
                                                                            {...register(`q${question?.id}${optionIndex+1}`, {
                                                                                required: true
                                                                            })}
                                                                            onChange={(e) => handleMultiCheck(e, option)}
                                                                            />
                                                                        </div> 
                                                                    </td>
                                                                    {(errors?.[`q${question?.id}${optionIndex+1}`]) ? <span className='text-danger'>{ERROR_MESSAGE?.MANDATORY}</span> : null}



                                                                </tr>
                                                            )
                                                        })
                                                        :null}
                                                    </table>
                                                </div>)
                                            }else if(question?.type == 'rating'){
                                                return(<div key={index} className='col-sm-12 col-12'>    
                                                <label className='question mt-2 mb-2' htmlFor={`q${question?.id}`}>{`Q${index+1}. ${question?.question}`}</label>
    
                                                <div className='d-flex mt-2'>
                                                    {ratingArray?.map((option, optionIndex) => {
                                                        return(
                                                            <div key={optionIndex} className="form-check d-inline-block radiobuttong">
                                                                
                                                                <input className="form-check-input " type="radio" value={option} id={`q${question?.id}${optionIndex+1}`}
                                                                {...register(`q${question?.id}`, {
                                                                    required: true
                                                                })}
                                                                onChange={(e) => handleOtherOption(e)}
                                                                />
                                                                <span className='pe-2 groupQuestion options'>{option}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                {(errors?.[`q${question?.id}`]) ? <span className='text-danger'>{ERROR_MESSAGE?.REQ_QUESTION_MSG}</span> : null}
                                            </div>)
                                            }
                                            
                                        }) : null}

                                        <div className='login-btn mt-5'>    
                                            <button 
                                                type='submit'
                                                name='submit'
                                                className='login'
                                                disabled={loader}
                                                >
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <span>Submit </span>  <span className='ms-2'>
                                                    {(loader) ? <Circles
                                                        height="20"
                                                        width="20"
                                                        style={{ display: 'inline-block' }}
                                                        color="#ffffff"
                                                        ariaLabel="circles-loading"
                                                        visible={true}
                                                    /> : null}
                                                </span>
                                            </div>
                                            </button>
                                        </div>
                                        

                                    </form>
                                </div>
                        
                            </div>}
                        </div>
                        
                    </div>
                </div>
                <Modal className='mt-5' isOpen={modalOpen} toggle={handleModal}>
                    <ModalHeader toggle={handleModal}>Instructions</ModalHeader>
                    <ModalBody>
                        <div className='w-100 text-start bg-grey m-0 p-1'>
                            <p> Kindly read the instructions below carefully before approaching the questionnaire:
                                <ul>
                                    <li>This is a research collecting data on the user experience of LDnil by HCP(Healthcare Professionals) in their clinical set up.</li>
                                    <li>Data are being collected through a questionnaire below containing 19 Questions.</li>
                                    <li>Collected data maybe used for publication purpose in the future without revealing personal identifiers.</li>
                                    <li>Filling the form may take around 10 minutes. No other potential risk is expected in from participation in the study.</li>
                                    <li>There is no provision of any compensation or incentive(s).</li>
                                    <li>You are absolutely free to decide whether or not to fill the form.</li>
                                    <li>You are requested to give responses purely based on your experience, current knowledge, attitude and practices followed without being influenced by other sources.</li>
                                    <li>You can ask any query related to the research to the concerned official collecting the responses. </li>
                                </ul>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
            </main>
		
	)
}

Exam.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
    isLoggedIn: PropTypes.bool,
}
  
Exam.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
    isLoggedIn: false,
}

function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
        loader: singInAndSingUp?.loader,
        isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(Exam)

