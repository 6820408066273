import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import URL from '../../constants/urls'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import {handleUserLogOut} from '../../redux/actions/singinAndSingUp'
import logo from '../../assets/img/msn-logo.jpg'
import { useContext } from 'react'
import MSNlogofinal from '../../assets/img/MSN-logo-final.png'

function Header(props) {
    const {dispatch, data, displayLogo, setIsLoggedIn, isLoggedIn} = props
    const navigate = useNavigate()
    const [userData, setUserData] = useState(EMPTY_OBJECT)
    const [displaySubMenu, setDisplaySubMenu] = useState(false)
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false)
    const handleLoginRedirect = () => {
        navigate(`${URL?.LOGIN}`)
    }
    const handleSignUpRedirect = () => {
        navigate(`${URL?.SIGNUP}`)
    }
    
    useEffect(() => {
        setUserData(data?.user)
        const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : EMPTY_OBJECT
        setUserData(userData)
        
    },[EMPTY_ARRAY, isLoggedIn, JSON.stringify(data)])

    
    const handleLogout = () => {
        dispatch(handleUserLogOut())
        navigate('/login')
        setUserData(null)
        setIsLoggedIn(false)
    }

    const handleSubMenu = () => {
        setDisplaySubMenu(!displaySubMenu)
    }

    const handleMobileMenu = () => {
        setIsMobileMenuActive(!isMobileMenuActive)
    }
    return (
        <div className="nav-bar">
                <div className="nav-container">
                    <span className="text-white fw-bold text-decoration-none cursor" onClick={() => handleLoginRedirect()}>
                        {displayLogo ? <div className="brand mt-2">
                            <div className="subheadlogo">This survey is brought to you by</div>
                                <img src={MSNlogofinal} alt="BeRifma" className='logoMain'/>
                        </div> : <div className='width-fit-content d-inline-block bg-black pb-1'>
                                <h2 className='p-2 pb-0 mb-0 ps-3'>LDNIL</h2>
                                <small className='fw-normal p-2 pb-0 mb-0 small ps-3'>User Experience Survey</small>
                            </div> }
                    </span>
                    <nav>
                        <div className="nav-mobile" onClick={() => handleMobileMenu()}>
                            <span id="nav-toggle" className={isMobileMenuActive ? 'active' : ''}>
                                <span></span>
                            </span>
                        </div>
                            {/* <li><Link to={'/'}>Home</Link></li> */}
                            {/* <li><Link to={'/'}>About</Link></li>
                            <li><span>Services</span>
                                <ul className="nav-dropdown">
                                    <li><Link to={'/'}>Link 1</Link></li>
                                    <li><Link to={'/'}>Link 1</Link></li>
                                    <li><Link to={'/'}>Link 1</Link></li>
                                </ul>
                            </li>
                            <li><Link to={'/'}>Careers</Link></li>
                            <li><Link to={'/'}>Contact</Link></li> */}
                            {userData && userData?.first_name && userData?.first_name != ''  ? 
                                <ul className={`nav-list ${isMobileMenuActive ? 'd-block' : 'd-none'}`}>
                                    <li >
                                        <span className="text-white text-decoration-none cursor" onClick={() => handleSubMenu()}>Welcome, {userData?.first_name} </span>
                                    </li>
                                    <li >
                                        <span className="text-white text-decoration-none cursor" onClick={() => handleLogout()}>Logout</span>
                                    </li>
                                </ul> : 
                                <ul className={`nav-list ${isMobileMenuActive ? 'd-block' : 'd-none'}`}>
                                    <li>
                                        <span className="text-white text-decoration-none cursor">
                                            <span onClick={() => handleLoginRedirect()}>Login</span>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="text-white text-decoration-none cursor">
                                            <span onClick={() => handleSignUpRedirect()}>Register</span>
                                        </span>
                                    </li>
                                    
                                </ul>}
                    </nav>
                </div>
                
            </div>
        
    )
}

Header.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
    data: PropTypes.object,
    displayLogo: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    setIsLoggedIn: PropTypes.func,
}
  
Header.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
    data: EMPTY_OBJECT,
    displayLogo: false,
    isLoggedIn: false,
    setIsLoggedIn: noop,
}

function mapStateToProps({ singInAndSingUp}) {
    return {
        loader: singInAndSingUp?.loader,
        data: singInAndSingUp?.data,
    }
}
export default connect(mapStateToProps)(Header)
