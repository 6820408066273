import React from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { ERROR_MESSAGE } from '../../constants/errorMessage'
import REGEX_PATTERN from '../../constants/regex'
import { sendEmailResetPassword } from '../../redux/actions/singinAndSingUp'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import { convertJSONtoFormData } from '../../utils/helper'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SideSection from '../../components/global/sideSection'
import Loader from '../../global/Loader'
import { useEffect } from 'react'
import URL from '../../constants/urls'

function ResetPassword(props) {
    const {dispatch, notify} = props
    const [loader, setLoader]= useState(false)
    const [verificationMessage, setVerificationMessage] = useState(null)
    const [verificationStatus, setVerificationStatus] = useState(false)
    const { token } = useParams()
    const navigate = useNavigate()
    
    useEffect(() => {
        handleVerification()
    },[EMPTY_ARRAY])

    const handleVerification = () => {
        setLoader(true)
        const payload = {
            apicall: 'verifymail',
            key: token,
            usertype: 'user'
        }
        const formData = convertJSONtoFormData(payload)
        dispatch(sendEmailResetPassword(formData)).then((res) => {
            setLoader(false)
            if(res && !res?.error){
                setVerificationStatus(true)
                setVerificationMessage(res?.message ? res?.message : 'Email is verified, Please Login to your account!')
                notify('success', res?.message ? res?.message : 'Email is verified, Please Login to your account!')
            }else{
                setVerificationStatus(false)
                setVerificationMessage(res?.message ? res?.message : 'Something went Wrong!')
                notify('error', res?.message ? res?.message : 'Something went Wrong!')
            }
        }).catch((err) =>{
            setLoader(false)
            setVerificationStatus(false)
            setVerificationMessage(err?.message ? err?.message : 'Something went Wrong!')
            notify('error', err?.message ? err?.message : 'Something went Wrong!')
        })
    }

    const navigateToLogin = () => {
        navigate(URL?.LOGIN)
    }

	return (
		<main className="overflow-hidden">
            <div className="wrapper">
                <div className="main-inner">
                    <div className="logo">    
                    </div>
                    <div className="row h-100 align-content-center maincontentwrap">
                        <SideSection/>
                        <div className="col-md-6 tab-100 order_2">
                            <div className="form">
                                <h2 className="login-form form-title">
                                    Email Verification
                                </h2>
                                {loader ? <Loader/> : <span><p className={verificationStatus ? 'text-center text-success' : 'text-center text-danger'}>
                                    {verificationMessage}
                                </p>
                                {verificationStatus ? <div className='col-sm-12 col-12 mt-2 mb-2 text-center'>    
                                    <span className='text-primary'>
                                        <span className='pointer forget' onClick={() => navigateToLogin()}>Login to your Account</span>
                                    </span>
                                </div> : null}
                                </span>}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
	)
}

ResetPassword.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
}
  
ResetPassword.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
}
function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
      loader: singInAndSingUp?.loader,
      isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(ResetPassword)
