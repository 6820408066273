export const EMPTY_ARRAY = Object.freeze([])
export const EMPTY_OBJECT = Object.freeze({})


export const CURRENCY = {
	usd: 'USD',
	euro: 'EURO',
	inr: 'INR'
}

export const STATUS = {
	active: 'Active',
	inactive: 'Inactive'
}

export const MONTH_NAMES = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
]
export const DAYS = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]
export const EXPIRY_MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
export const EXPIRY_YEARS = [
	{
		val: '22',
		label: '2022'
	},
	{
		val: '23',
		label: '2023'
	},
	{
		val: '24',
		label: '2024'
	},
	{
		val: '25',
		label: '2025'
	},
	{
		val: '26',
		label: '2026'
	},
	{
		val: '27',
		label: '2027'
	},
	{
		val: '28',
		label: '2028'
	},
	{
		val: '29',
		label: '2029'
	},
	{
		val: '30',
		label: '2030'
	},
	{
		val: '31',
		label: '2031'
	},
	{
		val: '32',
		label: '2032'
	},
	{
		val: '33',
		label: '2033'
	},
	{
		val: '34',
		label: '2034'
	},
	{
		val: '35',
		label: '2035'
	},
	{
		val: '36',
		label: '2036'
	},
	{
		val: '37',
		label: '2037'
	},
	{
		val: '38',
		label: '2038'
	},
	{
		val: '39',
		label: '2039'
	},
	{
		val: '40',
		label: '2040'
	},
	{
		val: '41',
		label: '2041'
	},
	{
		val: '42',
		label: '2042'
	},
	{
		val: '43',
		label: '2043'
	},
	{
		val: '44',
		label: '2044'
	},
	{
		val: '45',
		label: '2045'
	},
	{
		val: '46',
		label: '2046'
	},
	{
		val: '47',
		label: '2047'
	},
	{
		val: '48',
		label: '2048'
	},
	{
		val: '49',
		label: '2049'
	},
	{
		val: '50',
		label: '2050'
	}


]

export const MONTHNAME = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]
export const MONTHNAMESMALL = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
]
export const WEEKDAYS = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]

export const validEmail = new RegExp('/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+.)+[A-Za-z]+$/')
export const validLicenceNumber = new RegExp('^(?=.*[0-9]).{7,13}$')
export const validPhone = new RegExp('^[0-9]{10}$')

export const strings = {
	surveyHeading: 'Join the User Experience Survey on',
	statinUsage: 'Statin Usage',
	welcomeMessage: 'We welcome you to the User Experience Survey on Statins. This survey will take 10 minutes of your time only and will help us to understand your prescribing concerns. This exercise is being done to serve you better so that there is a better patient outcome.',
	poweredBy: 'Powered by',
}

export const ratingArray = [1,2,3,4,5,6,7,8,9,10]
export const speciality = [
	{value:'Cardio', label:'Cardio'},
	{value:'CP', label:'CP'},
	{value:'Diabeto', label:'Diabeto'},
	{value:'Endocrinologist', label:'Endocrinologist'},
	{value:'General Practitioner', label:'General Practitioner'},
	{value:'Nephro', label:'Nephro'},
]