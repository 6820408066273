const CONSTANTS = {
	// user details request 
	USER_DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
	USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
	USER_DETAILS_FAILURE: 'USER_DETAILS_FAILURE',

	// sms and email request 
	SMS_EMAIL_REQUEST: 'SMS_EMAIL_REQUEST',
	SMS_EMAIL_SUCCESS: 'SMS_EMAIL_SUCCESS',
	SMS_EMAIL_FAILURE: 'SMS_EMAIL_FAILURE',

	// download ticket request 
	DOWNLOAD_TICKET_REQUEST: 'DOWNLOAD_TICKET_REQUEST',
	DOWNLOAD_TICKET_SUCCESS: 'DOWNLOAD_TICKET_SUCCESS',
	DOWNLOAD_TICKET_FAILURE: 'DOWNLOAD_TICKET_FAILURE',

	// get facity
	GET_FACILTY_REQUEST: 'GET_FACILTY_REQUEST',
	GET_FACILTY_SUCCESS: 'GET_FACILTY_SUCCESS',
	GET_FACILTY_FAILURE: 'GET_FACILTY_FAILURE',

	// added payment card api contants
	POST_CARD_DETAILS_REQUEST: 'POST_CARD_DETAILS_REQUEST',
	POST_CARD_DETAILS_SUCCESS: 'POST_CARD_DETAILS_SUCCESS',
	POST_CARD_DETAILS_FAILURE: 'POST_CARD_DETAILS_FAILURE',

	EXTEND_CARD_DETAILS_REQUEST: 'EXTEND_CARD_DETAILS_REQUEST',
	EXTEND_CARD_DETAILS_SUCCESS: 'EXTEND_CARD_DETAILS_SUCCESS',
	EXTEND_CARD_DETAILS_FAILURE: 'EXTEND_CARD_DETAILS_FAILURE',

	EXTEND_CARD_HOUR_REQUEST: 'EXTEND_CARD_HOUR_REQUEST',
	EXTEND_CARD_HOUR_SUCCESS: 'EXTEND_CARD_HOUR_SUCCESS',
	EXTEND_CARD_HOUR_FAILURE: 'EXTEND_CARD_HOUR_FAILURE',


	// ticket validate constants
	TICKET_VALIDATE_REQUEST: 'TICKET_VALIDATE_REQUEST',
	TICKET_VALIDATE_SUCCESS: 'TICKET_VALIDATE_SUCCESS',
	TICKET_VALIDATE_FAILED: 'TICKET_VALIDATE_FAILED',

	// get ticket details
	GET_TICKET_DETAILS_REQUEST: 'GET_TICKET_DETAILS_REQUEST',
	GET_TICKET_DETAILS_SUCCESS: 'GET_TICKET_DETAILS_SUCCESS',
	GET_TICKET_DETAILS_FAILURE: 'GET_TICKET_DETAILS_FAILURE',
	// payment Card Post
	POST_CARD_PAYMENT_REQUEST: 'POST_CARD_PAYMENT_REQUEST',
	POST_CARD_PAYMENT_SUCCESS: 'POST_CARD_PAYMENT_SUCCESS',
	POST_CARD_PAYMENT_FAILURE: 'POST_CARD_PAYMENT_FAILURE',


	// End Current Parking
	END_PARKING_REQUEST: 'END_PARKING_REQUEST',
	END_PARKING_SUCCESS: 'END_PARKING_SUCCESS',
	END_PARKING_FAILURE: 'END_PARKING_FAILURE',

	// get ticket details
	GET_TICKET_LIST_REQUEST: 'GET_TICKET_LIST_REQUEST',
	GET_TICKET_LIST_SUCCESS: 'GET_TICKET_LIST_SUCCESS',
	GET_TICKET_LIST_FAILURE: 'GET_TICKET_LIST_FAILURE',

	// for sing -in
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',

	// for sing-up
	SINGUP_REQUEST: 'SINGUP_REQUEST',
	SINGUP_SUCCESS: 'SINGUP_SUCCESS',
	SINGUP_FAILURE: 'SINGUP_FAILURE',


	// Reset Password
	POST_RESET_PASSWORD_REQUEST: 'POST_RESET_PASSWORD_REQUEST',
	POST_RESET_PASSWORD_SUCCESS: 'POST_RESET_PASSWORD_SUCCESS',
	POST_RESET_PASSWORD_FAILURE: 'POST_RESET_PASSWORD_FAILURE',


	// send email for forgot password
	SEND_EMAIL_RESET_PASSWORD_REQUEST: 'SEND_EMAIL_RESET_PASSWORD_REQUEST',
	SEND_EMAIL_RESET_PASSWORD_SUCCESS: 'SEND_EMAIL_RESET_PASSWORD_SUCCESS',
	SEND_EMAIL_RESET_PASSWORD_FAILURE: 'SEND_EMAIL_RESET_PASSWORD_FAILURE',

	// HANDLE EVENT LOGS

	ADD_EVENT_LOG_REQUEST: 'ADD_EVENT_LOG_REQUEST',
	ADD_EVENT_LOG_SUCCESS: 'ADD_EVENT_LOG_SUCCESS',
	ADD_EVENT_LOG_FAILURE: 'ADD_EVENT_LOG_FAILURE',

	// HANDLE EVENT LOGS

	RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
	RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
	RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

	// promoCode request 
	EXTEND_TICKET_LIST_REQUEST: 'EXTEND_TICKET_LIST_REQUEST',
	EXTEND_TICKET_LIST_SUCCESS: 'EXTEND_TICKET_LIST_SUCCESS',
	EXTEND_TICKET_LIST_FAILURE: 'EXTEND_TICKET_LIST_FAILURE',
	// for prompt and update mobile number 

	PUT_MOBILE_NUMBER_REQUEST: 'PUT_MOBILE_NUMBER_REQUEST',
	PUT_MOBILE_NUMBER_SUCCESS: 'PUT_MOBILE_NUMBER_SUCCESS',
	PUT_MOBILE_NUMBER_FAILURE: 'PUT_MOBILE_NUMBER_FAILURE',

	// EXPORT REPORT
	EXPORT_REPORT_REQUEST: 'EXPORT_REPORT_REQUEST',
	EXPORT_REPORT_SUCCESS: 'EXPORT_REPORT_SUCCESS',
	EXPORT_REPORT_FAILURE: 'EXPORT_REPORT_FAILURE',

	// EXPORT REPORT
	SUBMIT_ANSWER_REQUEST: 'SUBMIT_ANSWER_REQUEST',
	SUBMIT_ANSWER_SUCCESS: 'SUBMIT_ANSWER_SUCCESS',
	SUBMIT_ANSWER_FAILURE: 'SUBMIT_ANSWER_FAILURE',

	// EXPORT REPORT
	FETCH_QUESTIONS_REQUEST: 'FETCH_QUESTIONS_REQUEST',
	FETCH_QUESTIONS_SUCCESS: 'FETCH_QUESTIONS_SUCCESS',
	FETCH_QUESTIONS_FAILURE: 'FETCH_QUESTIONS_FAILURE',

	// EXPORT REPORT
	VALIDATION_REQUEST: 'VALIDATION_REQUEST',
	VALIDATION_SUCCESS: 'VALIDATION_SUCCESS',
	VALIDATION_FAILURE: 'VALIDATION_FAILURE',
}

export default CONSTANTS
