import React from 'react'
import PublicRoutes from '../../routes/PublicRoutes'
import BG from '../../assets/img/bg10.jpg'

function App() {
	return (
		<div>
			<div className="ls-bg">
				<img className="ls-bg-inner" src={BG} alt=""/>
			</div>
			<PublicRoutes />
		</div>
	)
}

export default App
