import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { ERROR_MESSAGE } from '../../constants/errorMessage'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import REGEX_PATTERN from '../../constants/regex'
import URL from '../../constants/urls'
import { userSingIn } from '../../redux/actions/singinAndSingUp'
import { Circles } from 'react-loader-spinner'
import { useState } from 'react'
import { convertJSONtoFormData, encryptMailId } from '../../utils/helper'
import { exportReport } from '../../redux/actions/export&Download'
import { InputGroup } from 'react-bootstrap'
import { useEffect } from 'react'
import SideSection from '../../components/global/sideSection'

function Login(props) {
    const {dispatch, notify, setIsLoggedIn, isLoggedIn} = props
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const navigateToSignUp = () => {
        navigate(URL?.SIGNUP)
    }
    const navigateToResetPassword = () => {
        navigate(URL?.FORGOTPASSWORD)
    }

    useEffect(() => {
        const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : EMPTY_OBJECT
        if(userData?.examAttended == 1){
            navigate(URL?.THANKYOU)
        }else if(userData?.userid){
            navigate(URL?.EXAM)
        }

    }, [EMPTY_ARRAY, isLoggedIn])

    const handleLogin = (data) => {
        setLoader(true)
        const payload = {...data, apicall: 'login'}
        const formData = convertJSONtoFormData(payload)
        if(!loader){
            dispatch(userSingIn(formData)).then((res) => {
                setLoader(false)
                if(res && !res.error){
                    setIsLoggedIn(true)
                    localStorage.setItem('user', JSON.stringify(res?.user))
                    notify('success', res?.message ? res?.message : 'LoggedIn Successfully!')
                    if(res?.user?.examAttended == 1){
                        navigate(URL?.THANKYOU)
                    }else{
                        navigate(URL?.EXAM)
                    }
                }else{
                    setIsLoggedIn(false)
                    notify('error', res?.message ? res?.message : 'Something went Wrong!')
                }
            }).catch((err) =>{
                setIsLoggedIn(false)
                setLoader(false)
                notify('error', err?.message ? err?.message : 'Error in Login In!')
            })
        }
    }

    const togglePasswordVisiblity = () => {
        setShowPassword(!showPassword)
    }
    
	return (
        <main className="overflow-hidden">
            <div className="wrapper">
                <div className="main-inner">
                    <div className="logo">    
                    </div>
                    <div className="row h-100 align-content-center maincontentwrap">
                        <SideSection/>
                        <div className="col-md-6 tab-100 order_2">
                            <div className="form">
                                    <h2 className="login-form form-title">
                                        Account Login
                                    </h2>
                                    <form id="step1" autoComplete='off' className='login-form d-flex row' onSubmit={handleSubmit(handleLogin)}>
                                    
                                        <div className='input-field reveal'>    
                                            
                                            <input 
                                                {...register('username', {
                                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                })}
                                                type='text'
                                                name='username'
                                                id='username'
                                                autoComplete='off'
                                            />
                                            <label  htmlFor='username'>Username or Email <span className='text-danger'>*</span></label>

                                            {(errors?.username) ? <span className='text-danger'>{errors?.username?.message}</span> : null}
                                        </div>
                                        <div className='input-field reveal'>    
                                            {/* <InputGroup className="input-field reveal p-0 form-control mt-2 mb-2 d-flex align-items-center"> */}
                                            <input 
                                                {...register('password', {
                                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                    pattern: {
                                                        value: REGEX_PATTERN.PASSWORD_FORMAT,
                                                        message: ERROR_MESSAGE.PASSWORD_MESSAGE,
                                                    },
                                                })}
                                                type={showPassword ? 'text' : 'password'}
                                                name='password'
                                                id='password'
                                            />
                                            <label  htmlFor='password'>Password <span className='text-danger'>*</span></label>

                                            {/* <i className={`${showPassword ? 'fas fa-eye-slash ' : 'fas fa-eye '} loginPassIcon me-1 ms-1`} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
                                            </InputGroup> */}
                                            {(errors?.password) ? <span className='text-danger'>{errors?.password?.message}</span> : null}
                                        </div>
                                        <div className='d-flex justify-content-between flex-wrap'>    
                                            <div className='rememberme'>
                                                <input 
                                                    {...register('rememberme')}
                                                    className='me-2'
                                                    type='checkbox'
                                                    name='rememberme'
                                                    id='rememberme'  
                                                /><label htmlFor='rememberme'>Remember Me</label>
                                            </div>
                                        </div>
                                        <div className='login-btn'>    
                                            <button 
                                                type='submit'
                                                name='submit'
                                                className='login'
                                                disabled={loader}
                                                >
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <span>Login</span>  <span className='ms-2'>
                                                    {(loader) ? <Circles
                                                        height="20"
                                                        width="20"
                                                        style={{ display: 'inline-block' }}
                                                        color="#ffffff"
                                                        ariaLabel="circles-loading"
                                                        visible={true}
                                                    /> : null}
                                                </span>
                                            </div>
                                            </button>
                                        </div>
                                                        {/* <span onClick={() => handleExport()}>Download</span> */}
                                        <div className='col-sm-12 col-12 mt-2 mb-2'>    
                                            <span className='text-primary'>
                                                <span className='pointer forget' onClick={() => navigateToResetPassword()}>Lost your password?</span> | <span className='pointer forget' onClick={() => navigateToSignUp()}>Sign Up</span>
                                            </span>
                                        </div>

                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>		
	)
}

Login.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
    setIsLoggedIn: PropTypes.func,
    isLoggedIn: PropTypes.bool,
}
  
Login.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
    setIsLoggedIn: noop,
    isLoggedIn: false,
}

function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
        loader: singInAndSingUp?.loader,
        isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(Login)

