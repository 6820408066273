import React from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { ERROR_MESSAGE } from '../../constants/errorMessage'
import REGEX_PATTERN from '../../constants/regex'
import { sendEmailResetPassword } from '../../redux/actions/singinAndSingUp'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import { convertJSONtoFormData } from '../../utils/helper'
import { useState } from 'react'
import { Circles } from 'react-loader-spinner'
import { InputGroup } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import SideSection from '../../components/global/sideSection'

function ResetPassword(props) {
    const {dispatch, notify} = props
    const { register, handleSubmit, formState: { errors }, watch } = useForm()
    const [loader, setLoader]= useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [CShowPassword, setCPasswordShown] = useState(false)
    const { token } = useParams()
    

    const handleResetPassword = (data) => {
        setLoader(true)
        const payload = {...data,
            apicall: 'resetpwd',
            key: token,
            usertype: 'user'
        }
        const formData = convertJSONtoFormData(payload)
        dispatch(sendEmailResetPassword(formData)).then((res) => {
            setLoader(false)
            if(res && !res?.error){
                notify('success', res?.message ? res?.message : 'Password Updated, Please Login!')
            }else{
                notify('error', res?.message ? res?.message : 'Something went Wrong!')
            }
        }).catch((err) =>{
            setLoader(false)
            notify('error', err?.message ? err?.message : 'Something went Wrong!')
        })
    }

    const togglePasswordVisiblity = (e, key) => {
        if (key === 'password') { setShowPassword(!showPassword)}
        if (key === 'confirm_password') { setCPasswordShown(!CShowPassword)}
    }

	return (
		<main className="overflow-hidden">
            <div className="wrapper">
                <div className="main-inner">
                    <div className="logo">    
                    </div>
                    <div className="row h-100 align-content-center maincontentwrap">
                        <SideSection/>
                        <div className="col-md-6 tab-100 order_2">
                            <div className="form">
                                <h2 className="login-form form-title">
                                    Reset Password
                                </h2>

                            <p>Enter the new password below, keep these password with you for Logging in future.</p>
                            <form id="step1" autoComplete='on' className='login-form d-flex row' onSubmit={handleSubmit(handleResetPassword)}>
                                <div className='input-field reveal'> 
                                    {/* <InputGroup> */}
                                    <input 
                                        {...register('email', {
                                            required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                            minLength: { value: 4, message: `${ERROR_MESSAGE?.MIN_ERROR_MSG} 4` },
                                            maxLength: { value: 40, message: `${ERROR_MESSAGE?.MAX_ERROR_MSG} 40` }
                                        })}
                                        className='form-control m-0'
                                        type={'text'}
                                        name='email'
                                        id='email'
                                    />
                                    <label className='fw-normal mt-2 mb-2'  htmlFor='email'>Email/Username <span className='text-danger'>*</span></label>

                                    {/* </InputGroup> */}
                                    {(errors?.email) ? <span className='text-danger'>{errors?.email?.message}</span> : null}
                                </div>
                                <div className='input-field reveal'> 
                                    {/* <InputGroup className='border rounded align-items-center d-flex'> */}
                                    <input 
                                        {...register('password', {
                                            required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                            pattern: {
                                                value: REGEX_PATTERN.PASSWORD_FORMAT,
                                                message: ERROR_MESSAGE.PASSWORD_MESSAGE,
                                            },
                                        })}
                                        className='form-control m-0'
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        id='password'
                                    />
                                    <label className='fw-normal mt-2 mb-2'  htmlFor='password'>Password <span className='text-danger'>*</span></label>

                                    {/* <i className={`${showPassword ? 'fas fa-eye-slash ' : 'fas fa-eye '} loginPassIcon me-1 ms-1`} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
                                    </InputGroup> */}
                                    {(errors?.password) ? <span className='text-danger'>{errors?.password?.message}</span> : null}
                                </div>
                                <div className='input-field reveal'> 
                                    {/* <InputGroup className='border rounded align-items-center d-flex'> */}
                                    <input 
                                        {...register('confirm_password', {
                                            required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                            pattern: {
                                                value: REGEX_PATTERN.PASSWORD_FORMAT,
                                                message: ERROR_MESSAGE.PASSWORD_MESSAGE,
                                            },
                                            validate: (val) => {
                                                if (watch('password') != val) {
                                                    return 'Passwords does not match'
                                                }
                                            },
                                        })}
                                        className='form-control m-0'
                                        type={CShowPassword ? 'text' : 'password'}
                                        name='confirm_password'
                                        id='confirm_password'
                                    />
                                    <label className='fw-normal mt-2 mb-2'  htmlFor='password'>Confirm Password <span className='text-danger'>*</span></label>

                                    {/* <i className={`${CShowPassword ? 'fas fa-eye-slash ' : 'fas fa-eye '} loginPassIcon me-1 ms-1`} onClick={(e) => togglePasswordVisiblity(e, 'confirm_password')}></i>
                                    </InputGroup> */}
                                    {(errors?.confirm_password) ? <span className='text-danger'>{errors?.confirm_password?.message}</span> : null}
                                </div>
                                <div className='login-btn'> 
                                    <button 
                                        type='submit'
                                        name='submit'
                                        disabled={loader}
                                        className='login'
                                        >
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <span>Reset Password </span>  <span className='ms-2'>
                                                {(loader) ? <Circles
                                                    height="20"
                                                    width="20"
                                                    style={{ display: 'inline-block' }}
                                                    color="#ffffff"
                                                    ariaLabel="circles-loading"
                                                    visible={true}
                                                /> : null}
                                            </span>
                                        </div>
                                    </button>
                                </div>
                            </form> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
	)
}

ResetPassword.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
}
  
ResetPassword.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
}
function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
      loader: singInAndSingUp?.loader,
      isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(ResetPassword)
