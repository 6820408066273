import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { ERROR_MESSAGE } from '../../constants/errorMessage'
import REGEX_PATTERN from '../../constants/regex'
import URL from '../../constants/urls'
import VALIDATION_VALUES from '../../constants/validationValues'
import { userSingUp } from '../../redux/actions/singinAndSingUp'
import { convertJSONtoFormData, encryptMailId } from '../../utils/helper'
import { Circles } from 'react-loader-spinner'
import { useState } from 'react'
import { InputGroup } from 'react-bootstrap'
import { useEffect } from 'react'
import { EMPTY_ARRAY, EMPTY_OBJECT, speciality } from '../../constants/globalConstants'
import SideSection from '../../components/global/sideSection'

function SignUp(props) {
    const {dispatch, notify} = props
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, watch } = useForm()
    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [CShowPassword, setCPasswordShown] = useState(false)
    const [mailVerifyMessage, setMailVerifyMessage] = useState(null)

    
    const navigateToLogin = () => {
        navigate(URL?.LOGIN)
    }

    useEffect(() => {
        const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : EMPTY_OBJECT
        if(userData?.examAttended == 1){
            navigate(URL?.THANKYOU)
        }
    }, [EMPTY_ARRAY])

    const handleSignUp = (data) => {
        setLoader(true)
        const payload = {...data, apicall: 'signup'}
        const formData = convertJSONtoFormData(payload)
        dispatch(userSingUp(formData)).then((res) => {
            setLoader(false)
            if(res && !res.error){
                notify('success', res?.message ? res?.message : 'Form Submitted Successfully!')
                // navigate(URL?.EXAM)
                setMailVerifyMessage('You\'ll receive a confirmation email in your inbox with a link to activate your account. If you have any problems, contact us!')
            }else{
                notify('error', res?.message ? res?.message : 'Error in Registration!')
                setMailVerifyMessage(null)
            }
        }).catch((err) =>{
            setMailVerifyMessage(null)
            setLoader(false)
            notify('error', err?.message ? err?.message : 'Error in Registration!')
        })
    }

    const togglePasswordVisiblity = (e, key) => {
        if (key === 'password') { setShowPassword(!showPassword)}
        if (key === 'confirm_password') { setCPasswordShown(!CShowPassword)}
    }
    
	return (
        <main className="overflow-hidden">
            <div className="wrapper">
                <div className="main-inner">
                    <div className="logo">    
                    </div>
                    <div className="row h-100 align-content-center maincontentwrap">
                        <SideSection isSignUp/>
                        <div className="col-md-6 tab-100 order_2">
                            <div className="w-100 p-4 form">
                                <h2 className="login-form form-title">
                                    Create your Account!
                                </h2>
                                <form id="step1" autoComplete='off' className='login-form d-flex row' onSubmit={handleSubmit(handleSignUp)}>
                                    <div className='col-sm-6 col-12 mt-4'>
                                        <div className='input-field reveal'>  
                                            <input 
                                                {...register('email', {
                                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                    pattern: {
                                                        value: REGEX_PATTERN.EMAIL_FORMAT,
                                                        message: ERROR_MESSAGE.EMAIL_VALIDATION,
                                                    },
                                                })}
                                                className='form-control mt-2 mb-2'
                                                type='email'
                                                name='email'
                                                id='email'
                                            />
                                            <label className='fw-normal mt-2 mb-2' htmlFor='email'>Email Address <span className='text-danger'>*</span></label>

                                            {(errors?.email) ? <span className='text-danger'>{errors?.email?.message}</span> : null}
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-12 mt-4'>   
                                        <div className='input-field reveal'>  
                                            <input 
                                                {...register('username', {
                                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                })}
                                                className='form-control mt-2 mb-2'
                                                type='text'
                                                name='username'
                                                id='username'
                                            />
                                            <label className='fw-normal mt-2 mb-2' htmlFor='username'>Username <span className='text-danger'>*</span></label>

                                            {(errors?.username) ? <span className='text-danger'>{errors?.username?.message}</span> : null}
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-12 mt-4'> 
                                        <div className='input-field reveal'>  
                                            {/* <InputGroup className="p-0 form-control mt-2 mb-2 d-flex align-items-center"> */}
                                                <input 
                                                    {...register('password', {
                                                        required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                        pattern: {
                                                            value: REGEX_PATTERN.PASSWORD_FORMAT,
                                                            message: ERROR_MESSAGE.PASSWORD_MESSAGE,
                                                        },
                                                    })}
                                                    className='form-control m-0'
                                                    type={showPassword ? 'text' : 'password'}
                                                    name='password'
                                                    id='password'
                                                />
                                            <label className='fw-normal mt-2 mb-2'  htmlFor='password'>Password <span className='text-danger'>*</span></label>

                                                {/* <i className={`${showPassword ? 'fas fa-eye-slash ' : 'fas fa-eye '} loginPassIcon me-1 ms-1`} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
                                            </InputGroup> */}
                                            {(errors?.password) ? <span className='text-danger'>{errors?.password?.message}</span> : null}
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-12 mt-4'>
                                        <div className='input-field reveal'>  
                                            {/* <InputGroup className="p-0 form-control mt-2 mb-2 d-flex align-items-center"> */}
                                                <input 
                                                    {...register('confirm_password', {
                                                        required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                        pattern: {
                                                            value: REGEX_PATTERN.PASSWORD_FORMAT,
                                                            message: ERROR_MESSAGE.PASSWORD_MESSAGE,
                                                        },
                                                        validate: (val) => {
                                                            if (watch('password') != val) {
                                                                return 'Passwords does not match'
                                                            }
                                                        },
                                                    })}
                                                    className='form-control m-0'
                                                    type={CShowPassword ? 'text' : 'password'}
                                                    name='confirm_password'
                                                    id='confirm_password'
                                                    // placeholder='Confirm Password'
                                                />
                                            <label className='fw-normal mt-2 mb-2'  htmlFor='confirm_password'>Confirm Password <span className='text-danger'>*</span></label>

                                                {/* <i className={`${CShowPassword ? 'fas fa-eye-slash ' : 'fas fa-eye '} loginPassIcon me-1 ms-1`} onClick={(e) => togglePasswordVisiblity(e, 'confirm_password')}></i>
                                            </InputGroup> */}
                                            {(errors?.confirm_password) ? <span className='text-danger'>{errors?.confirm_password?.message}</span> : null}
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-12 mt-4'>
                                        <div className='input-field reveal'>  

                                            <input 
                                                {...register('first_name', {
                                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                })}
                                                className='form-control mt-2 mb-2'
                                                type='text'
                                                name='first_name'
                                                id='first_name'
                                            />
                                            <label className='fw-normal mt-2 mb-2' htmlFor='first_name'>First Name <span className='text-danger'>*</span></label>

                                            {(errors?.first_name) ? <span className='text-danger'>{errors?.first_name?.message}</span> : null}
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-12 mt-4'>
                                        <div className='input-field reveal'>  

                                            <input
                                                {...register('last_name', {
                                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                })} 
                                                className='form-control mt-2 mb-2'
                                                type='text'
                                                name='last_name'
                                                id='last_name'
                                            />
                                            <label className='fw-normal mt-2 mb-2' htmlFor='last_name'>Last Name <span className='text-danger'>*</span></label>

                                            {(errors?.last_name) ? <span className='text-danger'>{errors?.last_name?.message}</span> : null}
                                        </div>
                                    </div>
                                    {/* <div className='col-sm-6 col-12 mt-4'>
                                        <div className='input-field reveal'>  

                                        <label className='fw-normal mt-2 mb-2' htmlFor='username'>Display Name <span className='text-danger'>*</span></label>
                                        <input 
                                            {...register('display_name', {
                                                required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                            })}
                                            className='form-control mt-2 mb-2'
                                            type='text'
                                            name='display_name'
                                            id='display_name'
                                            placeholder='Display Name'
                                        />
                                        {(errors?.display_name) ? <span className='text-danger'>{errors?.display_name?.message}</span> : null}
                                        </div>
                                    </div> */}
                                    <div className='col-sm-6 col-12 mt-4'>
                                        <div className='input-field reveal'>  

                                            <input 
                                                {...register('phone', {
                                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                    pattern: {
                                                        value: REGEX_PATTERN.NUMERIC_FORMAT,
                                                        message: ERROR_MESSAGE.PHONE_VALIDATION_MESSAGE,
                                                    },
                                                    minLength: {
                                                        value: VALIDATION_VALUES.MAX_VALUE_10,
                                                        message:
                                                        ERROR_MESSAGE.MIN_ERROR_MSG +
                                                        VALIDATION_VALUES.MAX_VALUE_10,
                                                    },
                                                    maxLength: {
                                                        value: VALIDATION_VALUES.MAX_VALUE_10,
                                                        message:
                                                        ERROR_MESSAGE.MAX_ERROR_MSG +
                                                        VALIDATION_VALUES.MAX_VALUE_10,
                                                    },
                                                })}
                                                className='form-control mt-2 mb-2'
                                                type='text'
                                                name='phone'
                                                id='phone'
                                                maxLength={VALIDATION_VALUES.MAX_VALUE_10}
                                                minLength={VALIDATION_VALUES.MAX_VALUE_10}
                                            />
                                            <label className='fw-normal mt-2 mb-2' htmlFor='phone'>Phone <span className='text-danger'>*</span></label>

                                            {(errors?.first_name) ? <span className='text-danger'>{errors?.first_name?.message}</span> : null}
                                        </div>
                                    </div>
                                    {/* <div className='col-sm-6 col-12 mt-4 d-none'>    
                                        <label className='fw-normal mt-2 mb-2' htmlFor='city'>City <span className='text-danger'>*</span></label>
                                        <input 
                                            {...register('city', {
                                                required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                            })}
                                            className='form-control mt-2 mb-2'
                                            type='text'
                                            name='city'
                                            id='city'
                                            placeholder='City'
                                        />
                                        {(errors?.city) ? <span className='text-danger'>{errors?.city?.message}</span> : null}
                                        </div>
                                    </div> */}
                                    {/* <div className='col-sm-6 col-12 mt-4 d-none'>    
                                        <label className='fw-normal mt-2 mb-2' htmlFor='state'>State <span className='text-danger'>*</span></label>
                                        <input 
                                            {...register('state', {
                                                required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                            })}
                                            className='form-control mt-2 mb-2'
                                            type='text'
                                            name='state'
                                            id='state'
                                            placeholder='State'
                                        />
                                        {(errors?.state) ? <span className='text-danger'>{errors?.state?.message}</span> : null}
                                        </div>
                                    </div> */}
                                    <div className='col-sm-6 col-12 mt-4'>
                                        <div className='input-field reveal'>  

                                            <input 
                                                {...register('country', {
                                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                })}
                                                className='form-control mt-2 mb-2'
                                                type='text'
                                                name='country'
                                                id='country'
                                            />
                                            <label className='fw-normal mt-2 mb-2' htmlFor='country'>Country <span className='text-danger'>*</span></label>

                                            {(errors?.country) ? <span className='text-danger'>{errors?.country?.message}</span> : null}
                                        </div>
                                    </div>
                                    {/* <div className='col-sm-6 col-12 mt-4'>
                                        <div className='input-field reveal'>  

                                        <label className='fw-normal mt-2 mb-2' htmlFor='username'>License No / Registration No <span className='text-danger'>*</span></label>
                                        <input 
                                            {...register('license', {
                                                required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                            })}
                                            className='form-control mt-2 mb-2'
                                            type='text'
                                            name='license'
                                            id='license'
                                            placeholder='License No / Registration No'
                                        />
                                        {(errors?.license) ? <span className='text-danger'>{errors?.license?.message}</span> : null}
                                        </div>
                                    </div> */}
                                    <div className='col-sm-6 col-12 mt-4'>
                                        <div className='input-field reveal'>  

                                            <select 
                                                {...register('speciality', {
                                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                })}
                                                className='form-control pb-2'
                                                type='text'
                                                name='speciality'
                                                id='speciality'
                                            >
                                                <option value=''></option>
                                                {speciality?.map((item, index) => {
                                                    return <option key={index} value={item?.value}>{item?.label}</option>
                                                })}
                                            </select>
                                            <label className='fw-normal mt-2 mb-2' htmlFor='speciality'>Speciality <span className='text-danger'>*</span></label>

                                        {(errors?.speciality) ? <span className='text-danger'>{errors?.speciality?.message}</span> : null}
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-12 mt-4'>
                                        <div className='input-field reveal'>  

                                            <input 
                                                {...register('hospital', {
                                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                                })}
                                                className='form-control mt-2 mb-2'
                                                type='text'
                                                name='hospital'
                                                id='hospital'
                                            />
                                            <label className='fw-normal mt-2 mb-2' htmlFor='hospital'>Hospital / Clinic Name<span className='text-danger'>*</span></label>

                                            {(errors?.hospital) ? <span className='text-danger'>{errors?.hospital?.message}</span> : null}
                                        </div>
                                    </div>

                                    <div className='login-btn mt-4'>    
                                        <button 
                                            type='submit'
                                            name='submit'
                                            disabled={loader}
                                            className='login'
                                            >
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <span>Register </span>  <span className='ms-2'>
                                                {(loader) ? <Circles
                                                    height="20"
                                                    width="20"
                                                    style={{ display: 'inline-block' }}
                                                    color="#ffffff"
                                                    ariaLabel="circles-loading"
                                                    visible={true}
                                                /> : null}
                                            </span>
                                        </div>
                                        </button>
                                    </div>


                                    
                                    <div className='col-sm-12 col-12 mb-2 text-end '>    
                                        <span className='text-primary'>
                                        <span className='pointer forget' onClick={() => navigateToLogin()}>Already have an Account? Login Here</span>
                                        </span>
                                    </div>

                                    {(errors?.password?.message == '*Please match the password format' || errors?.confirm_password?.message == '*Please match the password format') ? <div className='col-sm-12 col-12 mb-2 small'>    
                                        <small className='pointer'>*{ERROR_MESSAGE?.PASSWORD_INFO}</small>
                                    </div> : null}
                                    

                                </form>
                                {mailVerifyMessage ? <div className="text-success login-form register-text">
                                        {mailVerifyMessage}
                                    </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
	)
}

SignUp.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
}

SignUp.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
}
function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
        loader: singInAndSingUp?.loader,
        isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(SignUp)

