import { STORAGE_KEY } from '../constants'
import CryptoJS from 'crypto-js'
import Resizer from 'react-image-file-resizer'
import { toast } from 'react-toastify'
import { validPhone } from '../constants/globalConstants'
// import parkingpayments from '../assets/img/parkingpayments.png'
// import townsend from '../assets/img/townsend.png'
// import jordanImage from '../assets/img/IntraPark.png'
// import classicParking from '../assets/img/classicparkingLogo.jpg'

export const getBearerToken = () => localStorage.getItem(STORAGE_KEY.ID_TOKEN)
const partnerDataLocal = JSON.parse(localStorage.getItem('partnerData'))?.brand_setting?.logourl

export const restrictInputValues = (event) => {
  if (!`${event.target.value}${event.key}`.match(/^[0-9]{0,6}$/)) {
    // block the input if result does not match
    event.preventDefault()
    event.stopPropagation()
    return false
  }
}

export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return pattern.test(email)
}

export const fileChangedHandler = (event, fileId, setUpdateFileState, fileWidth, fileHeight) => {
  let fileInput = false
  let file = document.getElementById(fileId).files[0]
  fileInput = file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif')
  if (fileInput) {
    try {
      Resizer.imageFileResizer(
        file,
        fileWidth,
        fileHeight,
        'PNG', // fileType(JPEG, PNG,WEBP)
        100,  // quality compression(for jpeg)
        0, // rotation(0-100)
        (uri) => {
          new Promise((resolve, reject) => {
            const img = new Image()
            // the following handler will fire after a successful loading of the image
            img.onload = () => {
              const { naturalWidth: width, naturalHeight: height } = img
              resolve({ width, height })
              setUpdateFileState({ [fileId]: uri })
            }
            // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
            img.onerror = () => {
              reject('There was some problem with the image.')
            }
            img.src = URL.createObjectURL(uri)
          })
        },
        'file' // outputType( base64, blob or file)
      )
    } catch (err) {
      setUpdateFileState({ fileId: '' })
    }
  } else {
    setUpdateFileState({ fileId: '' })
  }
}

export const encryptionHandler = (d) => {
  var nonceString = d.name_on_card + ':' + d.card_number + ':' + d.expiration_date + ':' + d.security_code + ':' + d.zip_code
  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = {
        ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
        iv: '',
        s: ''
      }
      if (cipherParams.iv)
        j.iv = cipherParams.iv.toString()
      if (cipherParams.salt)
        j.s = cipherParams.salt.toString()
      return JSON.stringify(j)
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr)
      var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
      if (j.iv)
        cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
      if (j.s)
        cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
      return cipherParams
    }
  }
  var encrypted = CryptoJS.AES.encrypt(JSON.stringify(nonceString), 'Tw5qNOJLe5GFe7rjFsentNNQBsRHstbNzTP/18lkjnY=', { format: CryptoJSAesJson }).toString()
  return encrypted
}


// to identifiy the type of the licence number

export const IdentifyLicenseNumber = (licenseNumber) => {
  const license_number = licenseNumber?.toUpperCase()
  if (license_number?.length === 9) {
    const alpha = license_number?.slice(0, 1)
    const number = license_number?.slice(1, 9)
    if (/^[a-zA-Z]+$/.test(alpha) && !/^[a-zA-Z]+$/.test(number)) {
      return 'Resident'
    } else {
      return 'Non-resident'
    }
  } else if (license_number !== '' && license_number?.length >= 7) {
    return 'Non-resident'
  } else {
    return ''
  }
}

export const notify = (type, message) => {
  if (type === 'error') {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT
    })
  } else if (type === 'success') {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT
    })
  }
}

export const phoneMasker = (phone) => {

  if (phone && phone.length >= 10) {
    let strlen = phone.length
    let start = strlen - 10
    let result = phone.slice(start, strlen)
    var USNumber = result.match(/(\d{3})(\d{3})(\d{4})/)
    return USNumber = '(' + USNumber[1] + ') ' + USNumber[2] + '-' + USNumber[3]
  } else {
    return '-'
  }

}
export const messageExtendSuccessPayment = 'Thank you for Parking with Us. Your Parking has been extended and ticket is sent to the registered Phone Number.'
export const messageSuccessPayment = 'Thank you for Parking with Us. Your e-Ticket details has been sent to the registered Phone Number.'
export const errorMessagePaymentFailed = 'Something went wrong. Please check your network connection and try again in some time. If your card is already charged, then please contact our support team.'

export const TimeConversion = (timeDuration) => {
  const daystoHours = Number(timeDuration?.days) * 24
  const minutestoHours = Number(timeDuration?.minutes) / 60
  const totalHours = Number(daystoHours) + Number(timeDuration?.hours) + Number(minutestoHours)
  return totalHours
}

export const getFieldValue = (name, e) => {
  return { target: { name: name, value: e } }
}


export const formattedExpiry = (expiry) => {
  return expiry.slice(0, 2) + '/' + expiry.slice(2)
}

export function loggedInUserData() {
  const userData = JSON.parse(localStorage.getItem('sign_in') ? localStorage.getItem('sign_in') : localStorage.getItem('sign_up') ? localStorage.getItem('sign_up') : '{}')
  return userData
}

export const passwordValidation = (value) => {
  return /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/i.test(value)
}

export const emailValidation = (value) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
}

export const newpasswordValidation = (value) => {
  return /\b(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#(){}$!%*?&])[A-Za-z\d@$!%*?&]{8,15}\b/i.test(value)
}

export const getLocation = (faciltyData) => {
  const location = faciltyData?.geolocations
  // return https://www.google.com/maps/dir//37.7726402,-122.4099154
  return `https://www.google.com/maps/dir//${location?.latitude},${location?.longitude}+(${faciltyData?.full_name?.replace(/ /g, '+')})`

}
export const validatePhone = (phone) => {
  // eslint-disable-next-line no-useless-escape
  return validPhone.test(phone)
}


export const getBreakUpDetails = (paymentData) => {
  let listItem = []
  if (paymentData?.parking_amount && (paymentData?.parking_amount != '0.00' || paymentData?.is_price_applicable == '1')) {
    listItem = [...listItem, { 'headerName': 'Parking Amount', 'val': `$${paymentData?.parking_amount ? Number(paymentData?.parking_amount).toFixed(2) : '0.00'}` }]
  }
  
  if ((paymentData?.paid_amount && paymentData?.paid_amount !== '' && (Number(paymentData?.paid_amount) > 0))) {
    listItem = [...listItem, { 'headerName': 'Validated Amount', 'val': paymentData?.paid_amount && Number(paymentData?.paid_amount) > 0 ? `- $${Number(paymentData?.paid_amount)?.toFixed(2)}` : '$0.00' }]
  }
  
  // if (paymentData?.discount_amount != '' && paymentData?.discount_amount != null) {
  //   listItem = [...listItem, { 'headerName': 'Discount Amount', 'val': `$${paymentData?.discount_amount != '' ? paymentData?.discount_amount : '-'}` }]
  // }

  // if ((paymentData?.discount_hours && paymentData?.discount_hours != '') || (paymentData?.discount_amount && paymentData?.discount_amount != '' && Number(paymentData?.discount_amount) > 0)) {
  //   listItem = [...listItem, { 'headerName': paymentData?.discount_hours ? `Validated ${paymentData?.discount_hours} Hrs Free` : '-', 'val': `- $${paymentData?.discount_hours ? paymentData?.discount_hours : '-'}` }]
  // }

  if (paymentData?.amount_paid && paymentData?.amount_paid !== '' && Number(paymentData?.amount_paid) > 0 && Number(paymentData?.payable_amount) > 0) {
    listItem = [...listItem, { 'headerName': 'Amount Paid', 'val': `- $${paymentData?.amount_paid ? Number(paymentData?.amount_paid)?.toFixed(2) : 0}` }]
  }


  if (paymentData?.overstay_amount && paymentData?.overstay_amount !== '' && paymentData?.overstay_amount != '0.00' && Number(paymentData?.overstay_amount) > 0) {
    listItem = [...listItem, { 'headerName': 'Overstay Amount', 'val': `$${paymentData?.overstay_amount ? Number(paymentData?.overstay_amount).toFixed(2) : 0}` }]
  }

  if ((paymentData?.discount_hours && paymentData?.discount_hours != '') || (paymentData?.discount_amount && paymentData?.discount_amount != '' && (Number(paymentData?.discount_amount) > 0)) || ((paymentData?.promocode_discount_hours && paymentData?.promocode_discount_hours != '') || (paymentData?.promocode_discount_amount && paymentData?.promocode_discount_amount != '' && (Number(paymentData?.promocode_discount_amount) > 0)))) {
    listItem = [...listItem, { 'headerName': (paymentData?.discount_hours || paymentData?.promocode_discount_hours) ? `Validated Amount (${paymentData?.discount_hours ? paymentData?.discount_hours : paymentData?.promocode_discount_hours} Hrs Free)` : 'Discount Amount', 'val': (paymentData?.discount_amount && Number(paymentData?.discount_amount) > 0) ? `- $${Number(paymentData?.discount_amount)?.toFixed(2)}` : (paymentData?.promocode_discount_amount && Number(paymentData?.promocode_discount_amount) > 0) ? `- $${Number(paymentData?.promocode_discount_amount)?.toFixed(2)}` : '$0.00' }]
  }

  if ((paymentData?.processing_fee && paymentData?.processing_fee != '0.00' && (paymentData?.is_overstay != 1 || paymentData?.is_extended != 1)) || paymentData?.is_price_applicable == '1') {
    listItem = [...listItem, { 'headerName': 'Processing Fee', 'val': `$${paymentData?.processing_fee ? Number(paymentData?.processing_fee)?.toFixed(2) : '0.00'}` }]
  }

  if (((paymentData?.tax_rate && paymentData?.tax_rate != '0.00') || (paymentData?.tax_fee && paymentData?.tax_fee != '0.00')) || paymentData?.is_price_applicable == '1') {
    listItem = [...listItem, { 'headerName': 'Tax', 'val': `$${paymentData?.tax_rate ? Number(paymentData?.tax_rate).toFixed(2) : paymentData?.tax_fee ? Number(paymentData?.tax_fee).toFixed(2) : '0.00'}` }]
  }


  return listItem

}
// export const getBreakUpDetails = (paymentData) => {
//   let listItem = []

//   if (paymentData?.parking_amount && paymentData?.parking_amount != '0.00') {
//     listItem = [...listItem, { 'headerName': 'Parking Amount', 'val': `$${paymentData?.parking_amount ? Number(paymentData?.parking_amount).toFixed(2) : '0.00'}` }]
//   }
//   if (paymentData?.processing_fee && paymentData?.processing_fee != '0.00' && paymentData?.parking_amount != '0.00' && Number(paymentData?.parking_amount) > 0) {
//     listItem = [...listItem, { 'headerName': 'Processing Fee', 'val': `$${paymentData?.processing_fee ? paymentData?.processing_fee : '0.00'}` }]
//   }

//   if (((paymentData?.tax_rate && paymentData?.tax_rate != '0.00') || (paymentData?.tax_fee && paymentData?.tax_fee != '0.00')) && paymentData?.parking_amount != '0.00' && Number(paymentData?.parking_amount) > 0) {
//     listItem = [...listItem, { 'headerName': 'Tax', 'val': `$${paymentData?.tax_rate ? Number(paymentData?.tax_rate).toFixed(2) : paymentData?.tax_fee ? Number(paymentData?.tax_fee).toFixed(2) : '0.00'}` }]
//   }

//   if (paymentData?.paid_amount && paymentData?.paid_amount !== '' && Number(paymentData?.paid_amount) > 0) {
//     listItem = [...listItem, { 'headerName': 'Validated Amount', 'val': `- $${paymentData?.paid_amount ? Number(paymentData?.paid_amount)?.toFixed(2) : 0}` }]
//   }

//   if ((paymentData?.discount_hours && paymentData?.discount_hours != '') || (paymentData?.discount_amount && paymentData?.discount_amount != '' && Number(paymentData?.discount_amount) > 0)) {
//     listItem = [...listItem, { 'headerName': paymentData?.discount_hours ? `Validated ${paymentData?.discount_hours} Hrs Free` : 'Discount Amount', 'val': `- $${paymentData?.discount_amount ? Number(paymentData?.discount_amount)?.toFixed(2) : '0.00'}` }]
//   }

//   // if (paymentData?.amount_paid && paymentData?.amount_paid !== '' && Number(paymentData?.amount_paid) > 0 && Number(paymentData?.payable_amount) > 0) {
//   //   listItem = [...listItem, { 'headerName': 'Amount Paid', 'val': `- $${paymentData?.amount_paid ? Number(paymentData?.amount_paid)?.toFixed(2) : 0}` }]
//   // }

//   if (paymentData?.overstay_amount && paymentData?.overstay_amount !== '' && paymentData?.overstay_amount != '0.00' && Number(paymentData?.overstay_amount) > 0) {
//     listItem = [...listItem, { 'headerName': 'Overstay Amount', 'val': `$${paymentData?.overstay_amount ? Number(paymentData?.overstay_amount).toFixed(2) : 0}` }]
//   }

//   // if (paymentData?.discount_amount && paymentData?.discount_amount != '' && Number(paymentData?.discount_amount) > 0) {
//   //   listItem = [...listItem, { 'headerName': paymentData?.discount_hours ? `Validated ${paymentData?.discount_hours} Hrs Free` : 'Discount Amount', 'val': `- $${paymentData?.discount_amount ? Number(paymentData?.discount_amount)?.toFixed(2) : 0}` }]
//   // }



//   return listItem

// }
//old function
// export const getBreakUpDetails = (paymentData) => {
//   let listItem = []

//   if (paymentData?.parking_amount && paymentData?.parking_amount != '0.00') {
//     listItem = [...listItem, { 'headerName': 'Parking Amount', 'val': `$${paymentData?.parking_amount ? Number(paymentData?.parking_amount).toFixed(2) : '0.00'}` }]
//   }
//   if (paymentData?.processing_fee && paymentData?.processing_fee != '0.00' && paymentData?.parking_amount != '0.00' && Number(paymentData?.parking_amount) > 0) {
//     listItem = [...listItem, { 'headerName': 'Processing Fee', 'val': `$${paymentData?.processing_fee ? paymentData?.processing_fee : '0.00'}` }]
//   }

//   if (((paymentData?.tax_rate && paymentData?.tax_rate != '0.00') || (paymentData?.tax_fee && paymentData?.tax_fee != '0.00')) && paymentData?.parking_amount != '0.00' && Number(paymentData?.parking_amount) > 0) {
//     listItem = [...listItem, { 'headerName': 'Tax', 'val': `$${paymentData?.tax_rate ? Number(paymentData?.tax_rate).toFixed(2) : paymentData?.tax_fee ? Number(paymentData?.tax_fee).toFixed(2) : '0.00'}` }]
//   }

//   if (paymentData?.paid_amount && paymentData?.paid_amount !== '' && Number(paymentData?.paid_amount) > 0) {
//     listItem = [...listItem, { 'headerName': 'Validated Amount', 'val': `- $${paymentData?.paid_amount ? Number(paymentData?.paid_amount)?.toFixed(2) : 0}` }]
//   }

//   if (paymentData?.discount_hours && paymentData?.discount_hours != '') {
//     listItem = [...listItem, { 'headerName': paymentData?.discount_hours ? `Validated ${paymentData?.discount_hours} Hrs Free` : '-', 'val': `- $${paymentData?.discount_hours ? paymentData?.discount_hours : '-'}` }]
//   }

//   if (paymentData?.amount_paid && paymentData?.amount_paid !== '' && Number(paymentData?.amount_paid) > 0 && Number(paymentData?.payable_amount) > 0) {
//     listItem = [...listItem, { 'headerName': 'Amount Paid', 'val': `- $${paymentData?.amount_paid ? Number(paymentData?.amount_paid)?.toFixed(2) : 0}` }]
//   }

//   if (paymentData?.overstay_amount && paymentData?.overstay_amount !== '' && paymentData?.overstay_amount != '0.00' && Number(paymentData?.overstay_amount) > 0) {
//     listItem = [...listItem, { 'headerName': 'Overstay Amount', 'val': `$${paymentData?.overstay_amount ? Number(paymentData?.overstay_amount).toFixed(2) : 0}` }]
//   }

//   if (paymentData?.discount_amount && paymentData?.discount_amount != '' && Number(paymentData?.discount_amount) > 0) {
//     listItem = [...listItem, { 'headerName': paymentData?.discount_hours ? `Validated ${paymentData?.discount_hours} Hrs Free` : 'Discount Amount', 'val': `- $${paymentData?.discount_amount ? Number(paymentData?.discount_amount)?.toFixed(2) : 0}` }]
//   }



//   return listItem

// }


export const isPromoValidateButton = (facilityData, conditionArray) => {
  // Check if is_promocode_validation_enabled's value is in the conditionArray
  const isPromocodeValidationEnabled = facilityData?.facility?.is_promocode_validation_enabled
  if (isPromocodeValidationEnabled !== undefined && conditionArray.includes(isPromocodeValidationEnabled)) {
    return true
  } else {
    return false
  }
}

export const priceGreaterOrNot = (ticketDetails) => {
  const paymentDataKeys = ['parking_amount', 'amount_paid', 'processing_fee', 'tax_rate', 'payable_amount', 'grand_total', 'discount_amount', 'paid_amount']
  let isGreaterOrNot = paymentDataKeys.map((key) => {
    if ((ticketDetails?.[key] && Number(ticketDetails?.[key]) > 0)) {
      return true
    } else {
      return false
    }
  })
  if (isGreaterOrNot.includes(true)) {
    return true
  }
}


export const getPartnerTagline = (name) => {

  const patnerName = name?.toLowerCase()
  switch (patnerName) {

    case 'parkingpayments':
      return 'Parking Payments'
    case 'townsend':
      return 'PCI Townsend Parking'
    case 'demoparkengage':
      return 'Parkengage Parking'
    case 'intrapark':
      return 'IntraPark Parking'
    default:
      return 'Parkengage Parking'
  }
}

export const getPartnerDetails = () => {
  const partner = localStorage.getItem('partnerData') ? JSON.parse(localStorage.getItem('partnerData')) : sessionStorage.getItem('partnerData') ? JSON.parse(sessionStorage.getItem('partnerData')) : ''
  return partner
}

export const isPartnerTownsend = (partner) => {
  return partner == 'townsend' || partner == 'pci' || partner == 'worldport-la'
}

export function sortPortalPermissionByKey(partnerDetails, sectionName) {
  return partnerDetails?.customer_portal_permission?.[sectionName]?.map((item) => item)
}

export const isPermissionExist = (data, itemName) => {
  const filterObj = (data?.length > 0) && data?.filter((item) => item?.display_name == itemName)
  if (filterObj[0]?.is_default == '1') {
    return true
  } else {
    return false
  }
}


export const getTotalOverstayPaidAmount = (paymentData) => {
  const totalPrice = paymentData && paymentData?.length > 0 ? paymentData?.map((item) => item?.grand_total ? item?.grand_total : 0).reduce((acc, total) => acc + Number(total), 0) : 0
  return Number(totalPrice).toFixed(2)
}

export const filterUnique = (array, property) => {
  return array?.filter((item, index, self) => {
    return self?.findIndex((t) => t[property] === item[property]) === index
  })
}

export const getSecondsLeft = (timeDifference, days, hours, minutes) => {
  const seconds = Math.floor(
    ((timeDifference % (1000 * 60 * 60)) / 1000) - (days * 24) - (hours * 60) - (minutes * 60)
  )
  return seconds
}

export const diff = (startTime, endTime) => {
  const startDate = new Date(startTime)
  const endDate = new Date(endTime)
  let timeDiff
  if (!isNaN(startDate) && !isNaN(endDate)) {
    const timeDifference = new Date(endDate) - new Date(startDate)
    // Calculate days, hours, and minutes
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    )
    //   const secondsLeft = getSecondsLeft(timeDifference, days, hours, minutes)
    let dayData = days > 0 ? ` ${days} ${days > 1 ? 'Days' : 'Day'}` : ''
    let hoursData =
      hours > 0 ? ` ${hours} ${hours > 1 ? 'Hours' : 'Hour'}` : ''
    // let minutesData = minutes > 0 ? ` ${secondsLeft > 0 ? minutes + 1 : minutes} Min` : ` ${secondsLeft > 0 ? minutes + 1 : minutes} Min`
    let minutesData = minutes > 0 ? `${minutes} Min` : ''

    timeDiff = ` ${dayData} ${hoursData} ${minutesData}`
  } else {
    timeDiff = 'N/A'
  }
  return timeDiff
  // 4 Days 11 Hours 30 Min
}

export const getFormattedTime = (totalhours, isConvert) => {

  const splittedTime = totalhours?.split('.')
  let minutes
  if (isConvert) {
    minutes = (splittedTime?.[1] / 100) * 60
  } else {
    minutes = splittedTime?.[1]
  }
  const hours = splittedTime ? `${splittedTime?.[0] == 0 ? '00' : splittedTime?.[0] == 0 ? '00' : Number(splittedTime?.[0]) < 10 ? `0${splittedTime?.[0]} ` : splittedTime?.[0]} : ${minutes != 0 ? minutes : '00'}` : ''
  return hours
}


export const encryptMailId = (user_email) => {
  var alphaArray = new Array()

		alphaArray['a']=1
        alphaArray['b']=2
        alphaArray['c']=3
        alphaArray['d']=4
        alphaArray['e']=5
        alphaArray['f']=6
        alphaArray['g']=7
        alphaArray['h']=8
        alphaArray['i']=9
        alphaArray['j']=10
        alphaArray['k']=11
        alphaArray['l']=12
        alphaArray['m']=13
        alphaArray['n']=14
        alphaArray['o']=15
        alphaArray['p']=16
        alphaArray['q']=17
        alphaArray['r']=18
        alphaArray['s']=19
        alphaArray['t']=20
        alphaArray['u']=21
        alphaArray['v']=22
        alphaArray['w']=23
        alphaArray['x']=24
        alphaArray['y']=25
        alphaArray['z']=26
        alphaArray['.']='.'
        alphaArray['@']='@'
        alphaArray['_']='_'
        alphaArray['-']='-'



		var UserEmail= user_email

		var userEmailArray=UserEmail.split('')

		var UserEmailDecode=''

		var deval

		for(var i=0; i<userEmailArray.length;i++){

			deval=userEmailArray[i]

			if(!alphaArray[userEmailArray[i]]){
			UserEmailDecode +=	'('+userEmailArray[i]+')!'
			}else{
			UserEmailDecode +=alphaArray[userEmailArray[i]]+'!'
			}
		}
    return UserEmailDecode
}

export const convertJSONtoFormData = (jsonObject) => {
  const formData = new FormData()

  Object.keys(jsonObject).forEach((key) => {
    formData.append(key, jsonObject[key])
  })

  return formData
}

export const isUserLoggedIn = () => {
  return document.cookie.includes('wordpress_logged_in_')
}


export const hasOtherValue = (data, keyToCheck) => {
 return data.some(item => item.key == keyToCheck && item.value === 'Others')
}