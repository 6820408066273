import React from 'react'
import SideSection from '../components/global/sideSection'



function Dummy() {
    
       
	return (
		<div>
            <main className="overflow-hidden">
            <div className="wrapper">
                <div className="main-inner">
                    <div className="logo">    
                    </div>
                    <div className="row h-100 align-content-center maincontentwrap">
                        <SideSection/>
                        <div className="col-md-6 tab-100 order_2">
                            <div className="form">
                                    <h2 className="login-form form-title">
                                        Account Login
                                    </h2>
                                    <h2 className="signup-form form-title">
                                        Create your Account!
                                    </h2>
                                <form id="step1" className="login-form" method="post">
                                    <div className="input-field reveal">
                                        <input type="text" id="username" required=""/>
                                        <label>
                                            Username or Email
                                        </label>
                                    </div>
                                    <div className="input-field delay-100ms reveal">
                                        <input type="password" id="password" required=""/>
                                        <label>
                                            Password
                                        </label>
                                    </div>
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <div className="rememberme">
                                            <input type="checkbox"/>
                                            <label>Remember Me</label>
                                        </div>
                                        <a href="#" className="forget">forget password</a>
                                    </div>
                                    <div className="login-btn">
                                        <button type="button" className="login">Login to your Account!</button>
                                    </div>
                                </form>

                                <form id="step2" className="signup-form" method="post">
                                    <div className="input-field reveal">
                                        <input type="text" id="mail-email" required=""/>
                                        <label>
                                            Your Email
                                        </label>
                                    </div>
                                    <div className="input-field delay-100ms reveal">
                                        <input type="text" id="user" required=""/>
                                        <label>
                                            Username
                                        </label>
                                    </div>
                                    <div className="input-field delay-200ms reveal">
                                        <input type="password" id="password" required=""/>
                                        <label>
                                            Password
                                        </label>
                                    </div>
                                    <div className="input-field delay-300ms reveal">
                                        <input type="password" id="confirm" required=""/>
                                        <label>
                                            Repeat Password
                                        </label>
                                    </div>
                                    <div className="rememberme">
                                        <input type="checkbox"/>
                                        <label>Send me news and updates via email</label>
                                    </div>
                                    <div className="login-btn">
                                        <button type="button" className="signup">Register Now!</button>
                                    </div>
                                </form>
                                <div className="signup-form register-text">
                                    You will receive a confirmation email in 
            your inbox with a link to activate your account. If you have any 
            problems, <a href="#">contact us!</a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
            <div id="error">
            </div>
        </div>
	)
}



export default Dummy
