import CONSTANTS from '../constants'
import { postRequest } from '../../services'
import { getConfigDetails } from '../../services/config'


// sing_in
export const fetchQuestions = (payload) => (dispatch) => {
  dispatch({ type: CONSTANTS.FETCH_QUESTIONS_REQUEST })
  return postRequest(getConfigDetails().baseURL, payload).then((res) => {
    dispatch({ type: CONSTANTS.FETCH_QUESTIONS_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.FETCH_QUESTIONS_FAILURE })
    return err
  })
}