import CONSTANTS from '../constants'
import { postRequest } from '../../services'
import { getConfigDetails } from '../../services/config'
import { EMPTY_OBJECT } from '../../constants/globalConstants'


// sing_in
export const userSingIn = (creds) => (dispatch) => {
  dispatch({ type: CONSTANTS.LOGIN_REQUEST })
  return postRequest(getConfigDetails().baseURL, creds).then((res) => {
    dispatch({ type: CONSTANTS.LOGIN_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.LOGIN_FAILURE })
    return err
  })
}

// sing_up

export const userSingUp = (creds) => (dispatch) => {
  dispatch({ type: CONSTANTS.SINGUP_REQUEST })
  return postRequest(getConfigDetails().baseURL, creds).then((res) => {
    dispatch({ type: CONSTANTS.SINGUP_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.SINGUP_FAILURE })
    return err
  })
}

export const sendEmailResetPassword = (payload) => (dispatch) => {
  dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_REQUEST })
  return postRequest(getConfigDetails().baseURL, payload).then((res) => {
    dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_FAILURE })
    return err
  })
}


// RESET PASSWORD
export const UpdateUserpassword = (payload) => (dispatch) => {
  dispatch({ type: CONSTANTS.RESET_PASSWORD_REQUEST })
  return postRequest(getConfigDetails().baseURL, payload).then((res) => {
    dispatch({ type: CONSTANTS.RESET_PASSWORD_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.RESET_PASSWORD_FAILURE })
    return err
  })
}


export const handleUserLogOut = () => (dispatch) => {
  localStorage.clear()
  dispatch({ type: CONSTANTS.LOGIN_SUCCESS, data: EMPTY_OBJECT })
}

// RESET PASSWORD
export const Validate = (payload) => (dispatch) => {
  dispatch({ type: CONSTANTS.VALIDATION_REQUEST })
  return postRequest(getConfigDetails().validationURL, payload).then((res) => {
    dispatch({ type: CONSTANTS.VALIDATION_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.VALIDATION_FAILURE })
    return err
  })
}