import React from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import URL from '../constants/urls'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import NotFound from '../components/global/NotFound'
import { toast, ToastContainer } from 'react-toastify'
import useNetwork from '../Hooks/NetworkDetector'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../constants/globalConstants'
import Login from '../pages/loginSignup/Login'
import SignUp from '../pages/loginSignup/SignUp'
import ForgotPassword from '../pages/loginSignup/ForgotPassword'
import 'react-toastify/dist/ReactToastify.css'
import ResetPassword from '../pages/loginSignup/ResetPassword'
import Exam from '../pages/ExamCenter/exam'
import Thankyou from '../pages/ExamCenter/thankyou'
import { useEffect } from 'react'
import { useState } from 'react'
import Dummy from '../pages/dummy'
import Header from '../components/global/Header'
import VerifyMail from '../pages/loginSignup/VerifyMail'
import { Validate } from '../redux/actions/singinAndSingUp'
import { convertJSONtoFormData } from '../utils/helper'

function PublicRoutes(props) {
    const {dispatch} = props
    const isOnline = useNetwork()
    const [displayLogo, setDisplayLogo] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [valid, setValid] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    
    useEffect(() => {
        const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : EMPTY_OBJECT
        if(userData?.userid){
            setIsLoggedIn(true)
        }else{
            setIsLoggedIn(false)
        }
    },[EMPTY_ARRAY])

    useEffect(() => {
        const location = window.location.href
        if(location == 'https://ldnilsurvey.com/' || location.includes('login') || location.includes('signup') || location == 'https://ldnilsurvey.com'
        || location.includes('forgot-password') || location.includes('reset-password') || location == 'http://localhost:3000/' || location == 'http://localhost:3000'){
            setDisplayLogo(true)
        }else{
            setDisplayLogo(false)
        }


        getValidation()

    }, [EMPTY_ARRAY, window.location.href])

    const notify = (type, message) => {
        if (type === 'error') {
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            })
            toast.clearWaitingQueue()
        } else if (type === 'success') {
            toast.success(message, {
                position: toast.POSITION.TOP_RIGHT
            })
            toast.clearWaitingQueue()
        }
    }

    const getValidation = () => {
        setIsFetching(true)
        const data = {
            apicall: 'sitevalidation',
            site: 'ldnilsurvey.com'
        }
        const formData = convertJSONtoFormData(data)
        dispatch(Validate(formData)).then((res) => {
            setIsFetching(false)
            if(res && !res?.error){
                setValid(true)
            }else{
                setValid(false)
            }
        }).catch(() => {
            setIsFetching(false)
            setValid(false)
        })

        // fetch('https://mitoinfotech.com/serviceApi/index', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Access-Control-Allow-Origin': '*',
        //             'Accept': 'application/json',
        //         },
        //         body: JSON.stringify(data)
        //     })
        //     .then(response => response.json())
        //     .then(data => console.log('Success:', data))
        //     .catch(error => console.error('Error:', error))
            
    }

    const DisplayToast = (text, title) => {
        return <div className='network-warning'>
            <span className='text-center fw-bold d-block'>{title}</span>
            <span className='text-center'>{text}</span>
        </div>
    }

   
    return (
        !isFetching ? <div>
            <BrowserRouter forceRefresh>
                    <Header displayLogo={displayLogo} setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn}/>
                        <div>
                            {!isOnline ?
                                DisplayToast('Please check the Internet Connection!', 'Warning') 
                            : null}
                            {valid ? 
                                <Routes>
                                    <Route
                                        path={URL.INDEX}
                                        element={
                                            <Login notify={notify} setIsLoggedIn={setIsLoggedIn}/>
                                        }
                                    />
                                    <Route
                                        path={URL.DEFAULTPATH}
                                        element={
                                            <Login notify={notify} setIsLoggedIn={setIsLoggedIn}/>
                                        }
                                    />
                                    <Route
                                        path={URL.HOME}
                                        element={
                                            <Login notify={notify} setIsLoggedIn={setIsLoggedIn}/>
                                        }
                                    />
                                    <Route
                                        path={URL.LOGIN}
                                        element={
                                            <Login notify={notify} setIsLoggedIn={setIsLoggedIn}/>
                                        }
                                    />
                                    <Route
                                        path={URL.SIGNUP}
                                        element={
                                            <SignUp notify={notify} />
                                        }
                                    />
                                    <Route
                                        path={URL.EXAM}
                                        element={
                                            <Exam notify={notify} isLoggedIn={isLoggedIn}/>
                                        }
                                    />
                                    <Route
                                        path={URL.THANKYOU}
                                        element={
                                            <Thankyou notify={notify} isLoggedIn={isLoggedIn} />
                                        }
                                    />
                                    
                                    <Route
                                        path={URL.FORGOTPASSWORD}
                                        element={
                                            <ForgotPassword notify={notify} />
                                        }
                                    />
                                    <Route
                                        path={URL.RESETPASSWORD}
                                        element={
                                            <ResetPassword notify={notify} />
                                        }
                                    />
                                    <Route
                                        path={URL.VERIFYMAIL}
                                        element={
                                            <VerifyMail notify={notify} />
                                        }
                                    />
                                    <Route
                                        path={URL.INVALID}
                                        element={
                                            <NotFound notify={notify} />
                                        }
                                    />
                                </Routes> :
                                
                                <Routes>
                                    <Route
                                        path={URL.INVALID}
                                        element={
                                            <NotFound notify={notify} />
                                        }
                                    />
                                </Routes>
                            }
                        </div>
            </BrowserRouter>
            <ToastContainer limit={1} />
        </div> : null
    )
}

PublicRoutes.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    isFetching: PropTypes.bool,
    cards: PropTypes?.array,
    notify: PropTypes?.func,
    isUserLogin: PropTypes?.bool,
}

PublicRoutes.defaulProps = {
    dispatch: noop,
    loader: true,
    isFetching: true,
    cards: EMPTY_ARRAY,
    notify: noop,
    isUserLogin: false,
}

function mapStateToProps({ gatedCheckinReducer, PaymentReducer, signInSignUpReducer, userDetailsReducer }) {
    return {
        loader: gatedCheckinReducer?.loader,
        cards: PaymentReducer?.data?.data?.payments,
        isUserLogin: signInSignUpReducer?.isUserLogin,
        isFetching: userDetailsReducer?.loader,

    }
}
export default connect(mapStateToProps)(PublicRoutes)