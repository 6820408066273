import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import {ERROR_MESSAGE } from '../../constants/errorMessage'
import Footer from './Footer'
function NotFound() {


    
    return (
        <main className="overflow-hidden internalpage">
                <div className="wrapper">
                    <div className="main-inner">
                        <div className="form w-100 ms-auto h-100 align-content-center maincontentwrap">
                        <div className='d-flex flex-column align-items-center m-0 p-0'>
                            <div>
                                <h1 className='text-center text-danger'>
                                    404<br/>{ERROR_MESSAGE?.NOT_FOUND}<br/>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

NotFound.propTypes = {
    dispatch: PropTypes.func,
    isFetchingPartner: PropTypes.bool,
}

NotFound.defaulProps = {
    dispatch: noop,
    isFetchingPartner: true,
}

function mapStateToProps({ userDetailsReducer }) {
    return {
        isFetchingPartner: userDetailsReducer?.isFetching
    }
}
export default connect(mapStateToProps)(NotFound)