import React from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { ERROR_MESSAGE } from '../../constants/errorMessage'
import REGEX_PATTERN from '../../constants/regex'
import { sendEmailResetPassword } from '../../redux/actions/singinAndSingUp'
import { EMPTY_OBJECT } from '../../constants/globalConstants'
import { convertJSONtoFormData } from '../../utils/helper'
import { useState } from 'react'
import { Circles } from 'react-loader-spinner'
import SideSection from '../../components/global/sideSection'

function ForgotPassword(props) {
    const {dispatch, notify} = props
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [loader, setLoader]= useState(false)

    const handleForgotPassword = (data) => {
        setLoader(true)

        const payload = {...data, apicall: 'forgotpwd', usertype: 'user'}
        const formData = convertJSONtoFormData(payload)
        dispatch(sendEmailResetPassword(formData)).then((res) => {
            setLoader(false)
            if(res && !res?.error){
                notify('success', res?.message ? res?.message : 'Password Reset Link Sent!')
            }else{
                notify('error', res?.message ? res?.message : 'Something went Wrong!')
            }
        }).catch((err) =>{
            setLoader(false)
            notify('error', err?.message ? err?.message : 'Something went Wrong!')
        })
    }
	return (
        <main className="overflow-hidden">
            <div className="wrapper">
                <div className="main-inner">
                    <div className="logo">    
                    </div>
                    <div className="row h-100 align-content-center maincontentwrap">
                        <SideSection/>
                        <div className="col-md-6 tab-100 order_2">
                            <div className="form">
                                <h2 className="login-form form-title">
                                    Forgot Password?
                                </h2>
                                <p>Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</p>
                                <form id="step1" autoComplete='on' className='login-form d-flex row' onSubmit={handleSubmit(handleForgotPassword)}>
                                    <div className='input-field reveal'> 
                                        <input
                                            {...register('username', {
                                                required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                            })} 
                                            className='form-control mt-2 mb-2'
                                            type='text'
                                            name='username'
                                            id='username'
                                        />
                                        <label className='fw-normal mt-2 mb-2' htmlFor='username'>Username or Email <span className='text-danger'>*</span></label>

                                        {(errors?.username) ? <span className='text-danger'>{errors?.username?.message}</span> : null}
                                    </div>
                                    <div className='login-btn'>   
                                    <button 
                                        type='submit'
                                        name='submit'
                                        disabled={loader}
                                        className='login'
                                        >
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <span>Reset Password </span>  <span className='ms-2'>
                                                {(loader) ? <Circles
                                                    height="20"
                                                    width="20"
                                                    style={{ display: 'inline-block' }}
                                                    color="#ffffff"
                                                    ariaLabel="circles-loading"
                                                    visible={true}
                                                /> : null}
                                            </span>
                                        </div>
                                    </button>
                                    </div>
                                    


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
	)
}

ForgotPassword.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
}
  
ForgotPassword.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
}
function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
      loader: singInAndSingUp?.loader,
      isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(ForgotPassword)
